export const strings = {
    "it": {
        //Login
        "Sign In": "Accedi",
        "Sign in": "Entra",
        "Enter your Email": "Inserisci la tua email",
        "Enter your Password": "Inserisci la tua password",
        "Create Account": "Registrati",
        "Forgot your password?": "Password dimenticata ?",
        "Continue As": "Continua come",
        "RedirectToWebsite": "Ti stiamo riportando a",
        "UserMigration failed with error User not found.": "Credenziali non valide",
        "Signing in": "Login in corso",
        "Incorrect username or password.": "Credenziali non valide",
        //Reset Psw
        "Reset Password": " ",
        "Enter your email": "Inserisci la tua email",
        "Send code": "Invia codice",
        "Back to Sign In": "Torna al login",
        //Confirm psw
        "Sending": "Attendere",
        "Code": "Codice",
        "New Password": "Nuova Password",
        "Submit": "Conferma",
        "Your passwords must match": "Le due password devono essere uguali",
        "Password must have at least 8 characters": "La password deve avere almeno 8 caratteri",
        "Submitting": "Attendere",
        //Signup
        "Optional": "Opzionale",
        "Firstname": "Nome",
        "Enter_Firstname": "Inserisci il nome",
        "Surname": "Cognome",
        "Enter_Surname": "Inserisci il cognome",
        "Phone": "Numero di telefono",
        "Enter_Phone": "Inserisci il numero di telefono",
        "Email": "Email",
        "Enter_Email": "Inserisci una email valida",
        "Password": "Password",
        "Enter_Password": "Inserisci una password",
        "Confirm Password": "Conferma Password",
        "Enter_ConfirmPassword": "Conferma la tua password",
        "Profile": "Profilo",
        "Select_Profile": "Seleziona un profilo",
        "Country": "Paese",
        "Select_Country": "Seleziona un Paese",
        "Company/Studio": "Azienda/Studio",
        "Enter_Company": "Inserisci il nome dell'azienda o dello studio",
        "I_read": "Ho letto",
        "Informativa_privacy": "l'informativa sulla privacy",
        "Accetto_privacy": "e accetto di ricevere comunicazioni da Effe",
        "Error_Profile": "Devi selezionare un profilo",
        "Error_Company_Studio": "Devi inserire un'azienda o studio",
        "Error_Privacy": "Devi accettare i termini e condizioni",
        "Error_Country": "Devi selezionare un paese",
        "Error_Password": "Devi inserire una password",
        "Error_ConfirmPassword": "Devi confermare la password",
        "Error_ConfirmPasswordMatch": "Le due password non coincidono",
        "Error_Email": "Devi inserire una email valida",
        "Error_Firstname": "Devi inserire un nome valido",
        "Error_Surname": "Devi inserire un cognome valido",
        //Confirm Account
        "We Emailed You": "Controlla la tua mail",
        "Your code is on the way. To log in, enter the code we emailed to": "Il codice è in arrivo. Per accedere, inserite il codice che vi abbiamo inviato via e-mail a",
        "It may take a minute to arrive": "L'arrivo potrebbe richiedere qualche minuto",
        "Confirmation Code": "Codice di conferma",
        "Enter your code": "Inserisci il codice ricevuto",
        "Confirm": "Conferma",
        "Resend Code": "Invia di nuovo il codice",
        //Profilo
        "Profile_Privato": "Privato",
        "Profile_Architetto_Designer": "Architetto/Designer",
        "Profile_Ingegnere_Progettista": "Ingegnere/Progettista",
        "Profile_Gestore_Hotel_SPA_Gym": "Gestore Hotel/SPA/Gym",
        "Profile_Costruttore": "Costruttore",
        "Profile_General_Contractor": "General Contractor",
        "Profile_Rivenditore": "Rivenditore",
        "Profile_Agenzia": "Agenzia",
        "Profile_Centro_Assistenza_Installatore": "Centro Assistenza/Installatore",
        "Profile_Altro": "Altro",
        //Country
        "Afghanistan": "Afghanistan",
        "Albania": "Albania",
        "Algeria": "Algeria",
        "Andorra": "Andorra",
        "Angola": "Angola",
        "Anguilla": "Anguilla",
        "Antigua and Barbuda": "Antigua e Barbuda",
        "Argentina": "Argentina",
        "Armenia": "Armenia",
        "Aruba": "Aruba",
        "Australia": "Australia",
        "Austria": "Austria",
        "Azerbaijan": "Azerbaigian",
        "Bahamas": "Bahamas",
        "Bahrain": "Bahrein",
        "Bangladesh": "Bangladesh",
        "Barbados": "Barbados",
        "Belarus": "Bielorussia",
        "Belgium": "Belgio",
        "Belize": "Belize",
        "Benin": "Benin",
        "Bermuda": "Bermuda",
        "Bhutan": "Bhutan",
        "Bolivia": "Bolivia",
        "Bosnia and Herzegovina": "Bosnia ed Erzegovina",
        "Botswana": "Botswana",
        "Brazil": "Brasile",
        "British Virgin Islands": "Isole Vergini Britanniche",
        "Brunei Darussalam": "Brunei",
        "Bulgaria": "Bulgaria",
        "Burkina Faso": "Burkina Faso",
        "Burundi": "Burundi",
        "Cambodia": "Cambogia",
        "Cameroon": "Camerun",
        "Canada": "Canada",
        "Cape Verde": "Capo Verde",
        "Cayman Islands": "Isole Cayman",
        "Central African Republic": "Repubblica Centrafricana",
        "Chad": "Ciad",
        "Chile": "Cile",
        "China": "Cina",
        "Colombia": "Colombia",
        "Comoros": "Comore",
        "Congo": "Congo",
        "Cook Islands (NZ)": "Isole Cook (NZ)",
        "Costa Rica": "Costa Rica",
        "Croatia": "Croazia",
        "Cuba": "Cuba",
        "Curaçao": "Curaçao",
        "Cyprus": "Cipro",
        "Czech Republic": "Repubblica Ceca",
        "Côte d'Ivoire": "Costa d'Avorio",
        "Democratic Republic of the Congo": "Repubblica Democratica del Congo",
        "Denmark": "Danimarca",
        "Djibouti": "Gibuti",
        "Dominica": "Dominica",
        "Dominican Republic": "Repubblica Dominicana",
        "Ecuador": "Ecuador",
        "Egypt": "Egitto",
        "El Salvador": "El Salvador",
        "Equatorial Guinea": "Guinea Equatoriale",
        "Eritrea": "Eritrea",
        "Estonia": "Estonia",
        "Eswatini": "Eswatini",
        "Ethiopia": "Etiopia",
        "Falkland Islands (Malvinas)": "Isole Falkland (Malvine)",
        "Far East": "Estremo Oriente",
        "Faroe Islands": "Isole Faroe",
        "Federated States of Micronesia": "Stati Federati di Micronesia",
        "Fiji": "Figi",
        "Finland": "Finlandia",
        "France": "Francia",
        "French Polynesia": "Polinesia Francese",
        "French Southern Territories": "Territori Francesi del Sud",
        "Gabon": "Gabon",
        "Gambia": "Gambia",
        "Georgia": "Georgia",
        "Germany": "Germania",
        "Ghana": "Ghana",
        "Gibraltar": "Gibilterra",
        "Greece": "Grecia",
        "Greenland": "Groenlandia",
        "Grenada": "Grenada",
        "Guatemala": "Guatemala",
        "Guernsey": "Guernsey",
        "Guinea": "Guinea",
        "Guinea-Bissau": "Guinea-Bissau",
        "Guyana": "Guyana",
        "Haiti": "Haiti",
        "Honduras": "Honduras",
        "Hungary": "Ungheria",
        "Iceland": "Islanda",
        "India": "India",
        "Indonesia": "Indonesia",
        "Iran": "Iran",
        "Iraq": "Iraq",
        "Ireland": "Irlanda",
        "Isle of Man": "Isola di Man",
        "Israel": "Israele",
        "Italia": "Italia",
        "Jamaica": "Giamaica",
        "Japan": "Giappone",
        "Jersey": "Jersey",
        "Jordan": "Giordania",
        "Kazakhstan": "Kazakistan",
        "Kenya": "Kenya",
        "Kiribati": "Kiribati",
        "Kosovo": "Kosovo",
        "Kuwait": "Kuwait",
        "Kyrgyzstan": "Kirghizistan",
        "Laos": "Laos",
        "Latvia": "Lettonia",
        "Lebanon": "Libano",
        "Lesotho": "Lesotho",
        "Liberia": "Liberia",
        "Libya": "Libia",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Lituania",
        "Luxembourg": "Lussemburgo",
        "North Macedonia": "Macedonia del Nord",
        "Madagascar": "Madagascar",
        "Malawi": "Malawi",
        "Malaysia": "Malaysia",
        "Maldives": "Maldive",
        "Mali": "Mali",
        "Malta": "Malta",
        "Marshall Islands": "Isole Marshall",
        "Mauritania": "Mauritania",
        "Mauritius": "Mauritius",
        "Mexico": "Messico",
        "Moldova": "Moldavia",
        "Monaco": "Monaco",
        "Mongolia": "Mongolia",
        "Montenegro": "Montenegro",
        "Montserrat": "Montserrat",
        "Morocco": "Marocco",
        "Mozambique": "Mozambico",
        "Myanmar/Burma": "Myanmar/Birmania",
        "Namibia": "Namibia",
        "Nauru": "Nauru",
        "Nepal": "Nepal",
        "Netherlands": "Paesi Bassi",
        "New Caledonia": "Nuova Caledonia",
        "New Zealand": "Nuova Zelanda",
        "Nicaragua": "Nicaragua",
        "Niger": "Niger",
        "Nigeria": "Nigeria",
        "North Korea": "Corea del Nord",
        "Norway": "Norvegia",
        "Oman": "Oman",
        "Pakistan": "Pakistan",
        "Palau": "Palau",
        "Palestine": "Palestina",
        "Panama": "Panama",
        "Papua New Guinea": "Papua Nuova Guinea",
        "Paraguay": "Paraguay",
        "Peru": "Perù",
        "Philippines": "Filippine",
        "Pitcairn": "Pitcairn",
        "Poland": "Polonia",
        "Portugal": "Portogallo",
        "Puerto Rico": "Porto Rico",
        "Qatar": "Qatar",
        "Romania": "Romania",
        "Russian Federation": "Federazione Russa",
        "Rwanda": "Ruanda",
        "Saint Barthelemy": "Saint Barthelemy",
        "Saint Helena": "Sant'Elena",
        "Saint Kitts and Nevis": "Saint Kitts e Nevis",
        "Saint Lucia": "Saint Lucia",
        "Saint Martin (FR)": "Saint Martin (FR)",
        "Saint Pierre and Miquelon": "Saint Pierre e Miquelon",
        "Saint Vincent and the Grenadines": "Saint Vincent e Grenadine",
        "Samoa": "Samoa",
        "San Marino": "San Marino",
        "San Marino (RSM)": "San Marino (RSM)",
        "Sao Tome and Principe": "Sao Tome e Principe",
        "Sark": "Sark",
        "Saudi Arabia": "Arabia Saudita",
        "Senegal": "Senegal",
        "Serbia": "Serbia",
        "Seychelles": "Seychelles",
        "Sierra Leone": "Sierra Leone",
        "Singapore": "Singapore",
        "Slovakia": "Slovacchia",
        "Slovenia": "Slovenia",
        "Solomon Islands": "Isole Salomone",
        "Somalia": "Somalia",
        "South Africa": "Sudafrica",
        "South Korea": "Corea del Sud",
        "South Sudan": "Sudan del Sud",
        "Spain": "Spagna",
        "Sri Lanka": "Sri Lanka",
        "St Maarten (NL)": "St Maarten (NL)",
        "Sudan": "Sudan",
        "Suriname": "Suriname",
        "Sweden": "Svezia",
        "Switzerland": "Svizzera",
        "Syria": "Siria",
        "Taiwan": "Taiwan",
        "Tajikistan": "Tagikistan",
        "Tanzania": "Tanzania",
        "Thailand": "Thailandia",
        "Timor Leste": "Timor Est",
        "Togo": "Togo",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidad e Tobago",
        "Tunisia": "Tunisia",
        "Turkey": "Turchia",
        "Turkmenistan": "Turkmenistan",
        "Turks and Caicos Islands": "Isole Turks e Caicos",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "Ucraina",
        "United Arab Emirates": "Emirati Arabi Uniti",
        "United Kingdom": "Regno Unito",
        "United States of America": "Stati Uniti d'America",
        "Uruguay": "Uruguay",
        "Uzbekistan": "Uzbekistan",
        "Vanuatu": "Vanuatu",
        "Vatican City State": "Città del Vaticano",
        "Venezuela": "Venezuela",
        "Vietnam": "Vietnam",
        "Wallis and Futuna Islands": "Isole Wallis e Futuna",
        "Western Sahara": "Sahara Occidentale",
        "Yemen": "Yemen",
        "Zambia": "Zambia",
        "Zimbabwe": "Zimbabwe"
    },
    "en": {
        //Login
        "Sign In": "Sign In",
        "Sign in": "Sign in",
        "Enter your Email": "Enter your Email",
        "Enter your Password": "Enter your Password",
        "Create Account": "Create Account",
        "Forgot your password?": "Forgot your password?",
        "Continue As": "Continue As",
        "RedirectToWebsite": "We are bringing you back to",
        "UserMigration failed with error User not found.": "Invalid credentials",
        "Signing in": "Signing in",
        //Reset Psw
        "Reset Password": "Forgot Password",
        "Enter your email": "Enter your email",
        "Send code": "Send code",
        "Back to Sign In": "Back to Sign In",
        //Signup
        "Optional": "Optional",
        "Firstname": "Firstname",
        "Enter_Firstname": "Enter Firstname",
        "Surname": "Surname",
        "Enter_Surname": "Enter Surname",
        "Phone": "Phone",
        "Enter_Phone": "Enter Phone",
        "Email": "Email",
        "Enter_Email": "Enter a valid Email",
        "Password": "Password",
        "Enter_Password": "Enter Password",
        "Confirm Password": "Confirm Password",
        "Enter_ConfirmPassword": "Confirm your Password",
        "Profile": "Profile",
        "Select_Profile": "Select a Profile",
        "Country": "Country",
        "Select_Country": "Select a Country",
        "Company/Studio": "Company/Studio",
        "Enter_Company": "Enter Company or Studio name",
        "I_read": "I have read",
        "Informativa_privacy": "the privacy policy",
        "Accetto_privacy": "and agree to receive communications from Effe",
        "Error_Profile": "You must select a profile",
        "Error_Company_Studio": "You must enter a company or studio",
        "Error_Privacy": "You must accept the terms and conditions",
        "Error_Country": "You must select a country",
        "Error_Password": "You must enter a password",
        "Error_ConfirmPassword": "You must confirm the password",
        "Error_ConfirmPasswordMatch": "The two passwords do not match",
        "Error_Email": "You must enter a valid email",
        "Error_Firstname": "You must enter a valid firstname",
        "Error_Surname": "You must enter a valid surname",
        //Confirm Account
        "We Emailed You": "Check your email",
        "Your code is on the way. To log in, enter the code we emailed to": "Your code is on the way. To log in, enter the code we emailed to",
        "It may take a minute to arrive": "It may take a minute to arrive",
        "Confirmation Code": "Confirmation Code",
        "Enter your code": "Enter your code",
        "Confirm": "Confirm",
        "Resend Code": "Resend Code",
        //Profilo
        "Profile_Privato": "Private",
        "Profile_Architetto_Designer": "Architect/Designer",
        "Profile_Ingegnere_Progettista": "Engineer/Planner",
        "Profile_Gestore_Hotel_SPA_Gym": "Hotel/SPA/Gym Manager",
        "Profile_Costruttore": "Builder",
        "Profile_General_Contractor": "General Contractor",
        "Profile_Rivenditore": "Dealer",
        "Profile_Agenzia": "Agency",
        "Profile_Centro_Assistenza_Installatore": "Service Center/Installer",
        "Profile_Altro": "Other",
        //Country
        "Afghanistan": "Afghanistan",
        "Albania": "Albania",
        "Algeria": "Algeria",
        "Andorra": "Andorra",
        "Angola": "Angola",
        "Anguilla": "Anguilla",
        "Antigua and Barbuda": "Antigua and Barbuda",
        "Argentina": "Argentina",
        "Armenia": "Armenia",
        "Aruba": "Aruba",
        "Australia": "Australia",
        "Austria": "Austria",
        "Azerbaijan": "Azerbaijan",
        "Bahamas": "Bahamas",
        "Bahrain": "Bahrain",
        "Bangladesh": "Bangladesh",
        "Barbados": "Barbados",
        "Belarus": "Belarus",
        "Belgium": "Belgium",
        "Belize": "Belize",
        "Benin": "Benin",
        "Bermuda": "Bermuda",
        "Bhutan": "Bhutan",
        "Bolivia": "Bolivia",
        "Bosnia and Herzegovina": "Bosnia and Herzegovina",
        "Botswana": "Botswana",
        "Brazil": "Brazil",
        "British Virgin Islands": "British Virgin Islands",
        "Brunei Darussalam": "Brunei Darussalam",
        "Bulgaria": "Bulgaria",
        "Burkina Faso": "Burkina Faso",
        "Burundi": "Burundi",
        "Cambodia": "Cambodia",
        "Cameroon": "Cameroon",
        "Canada": "Canada",
        "Cape Verde": "Cape Verde",
        "Cayman Islands": "Cayman Islands",
        "Central African Republic": "Central African Republic",
        "Chad": "Chad",
        "Chile": "Chile",
        "China": "China",
        "Colombia": "Colombia",
        "Comoros": "Comoros",
        "Congo": "Congo",
        "Cook Islands (NZ)": "Cook Islands (NZ)",
        "Costa Rica": "Costa Rica",
        "Croatia": "Croatia",
        "Cuba": "Cuba",
        "Curaçao": "Curaçao",
        "Cyprus": "Cyprus",
        "Czech Republic": "Czech Republic",
        "Côte d'Ivoire": "Côte d'Ivoire",
        "Democratic Republic of the Congo": "Democratic Republic of the Congo",
        "Denmark": "Denmark",
        "Djibouti": "Djibouti",
        "Dominica": "Dominica",
        "Dominican Republic": "Dominican Republic",
        "Ecuador": "Ecuador",
        "Egypt": "Egypt",
        "El Salvador": "El Salvador",
        "Equatorial Guinea": "Equatorial Guinea",
        "Eritrea": "Eritrea",
        "Estonia": "Estonia",
        "Eswatini": "Eswatini",
        "Ethiopia": "Ethiopia",
        "Falkland Islands (Malvinas)": "Falkland Islands (Malvinas)",
        "Far East": "Far East",
        "Faroe Islands": "Faroe Islands",
        "Federated States of Micronesia": "Federated States of Micronesia",
        "Fiji": "Fiji",
        "Finland": "Finland",
        "France": "France",
        "French Polynesia": "French Polynesia",
        "French Southern Territories": "French Southern Territories",
        "Gabon": "Gabon",
        "Gambia": "Gambia",
        "Georgia": "Georgia",
        "Germany": "Germany",
        "Ghana": "Ghana",
        "Gibraltar": "Gibraltar",
        "Greece": "Greece",
        "Greenland": "Greenland",
        "Grenada": "Grenada",
        "Guatemala": "Guatemala",
        "Guernsey": "Guernsey",
        "Guinea": "Guinea",
        "Guinea-Bissau": "Guinea-Bissau",
        "Guyana": "Guyana",
        "Haiti": "Haiti",
        "Honduras": "Honduras",
        "Hungary": "Hungary",
        "Iceland": "Iceland",
        "India": "India",
        "Indonesia": "Indonesia",
        "Iran": "Iran",
        "Iraq": "Iraq",
        "Ireland": "Ireland",
        "Isle of Man": "Isle of Man",
        "Israel": "Israel",
        "Italia": "Italy",
        "Jamaica": "Jamaica",
        "Japan": "Japan",
        "Jersey": "Jersey",
        "Jordan": "Jordan",
        "Kazakhstan": "Kazakhstan",
        "Kenya": "Kenya",
        "Kiribati": "Kiribati",
        "Kosovo": "Kosovo",
        "Kuwait": "Kuwait",
        "Kyrgyzstan": "Kyrgyzstan",
        "Laos": "Laos",
        "Latvia": "Latvia",
        "Lebanon": "Lebanon",
        "Lesotho": "Lesotho",
        "Liberia": "Liberia",
        "Libya": "Libya",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Lithuania",
        "Luxembourg": "Luxembourg",
        "North Macedonia": "North Macedonia",
        "Madagascar": "Madagascar",
        "Malawi": "Malawi",
        "Malaysia": "Malaysia",
        "Maldives": "Maldives",
        "Mali": "Mali",
        "Malta": "Malta",
        "Marshall Islands": "Marshall Islands",
        "Mauritania": "Mauritania",
        "Mauritius": "Mauritius",
        "Mexico": "Mexico",
        "Moldova": "Moldova",
        "Monaco": "Monaco",
        "Mongolia": "Mongolia",
        "Montenegro": "Montenegro",
        "Montserrat": "Montserrat",
        "Morocco": "Morocco",
        "Mozambique": "Mozambique",
        "Myanmar/Burma": "Myanmar/Burma",
        "Namibia": "Namibia",
        "Nauru": "Nauru",
        "Nepal": "Nepal",
        "Netherlands": "Netherlands",
        "New Caledonia": "New Caledonia",
        "New Zealand": "New Zealand",
        "Nicaragua": "Nicaragua",
        "Niger": "Niger",
        "Nigeria": "Nigeria",
        "North Korea": "North Korea",
        "Norway": "Norway",
        "Oman": "Oman",
        "Pakistan": "Pakistan",
        "Palau": "Palau",
        "Palestine": "Palestine",
        "Panama": "Panama",
        "Papua New Guinea": "Papua New Guinea",
        "Paraguay": "Paraguay",
        "Peru": "Peru",
        "Philippines": "Philippines",
        "Pitcairn": "Pitcairn",
        "Poland": "Poland",
        "Portugal": "Portugal",
        "Puerto Rico": "Puerto Rico",
        "Qatar": "Qatar",
        "Romania": "Romania",
        "Russian Federation": "Russian Federation",
        "Rwanda": "Rwanda",
        "Saint Barthelemy": "Saint Barthelemy",
        "Saint Helena": "Saint Helena",
        "Saint Kitts and Nevis": "Saint Kitts and Nevis",
        "Saint Lucia": "Saint Lucia",
        "Saint Martin (FR)": "Saint Martin (FR)",
        "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
        "Samoa": "Samoa",
        "San Marino": "San Marino",
        "San Marino (RSM)": "San Marino (RSM)",
        "Sao Tome and Principe": "Sao Tome and Principe",
        "Sark": "Sark",
        "Saudi Arabia": "Saudi Arabia",
        "Senegal": "Senegal",
        "Serbia": "Serbia",
        "Seychelles": "Seychelles",
        "Sierra Leone": "Sierra Leone",
        "Singapore": "Singapore",
        "Slovakia": "Slovakia",
        "Slovenia": "Slovenia",
        "Solomon Islands": "Solomon Islands",
        "Somalia": "Somalia",
        "South Africa": "South Africa",
        "South Korea": "South Korea",
        "South Sudan": "South Sudan",
        "Spain": "Spain",
        "Sri Lanka": "Sri Lanka",
        "St Maarten (NL)": "St Maarten (NL)",
        "Sudan": "Sudan",
        "Suriname": "Suriname",
        "Sweden": "Sweden",
        "Switzerland": "Switzerland",
        "Syria": "Syria",
        "Taiwan": "Taiwan",
        "Tajikistan": "Tajikistan",
        "Tanzania": "Tanzania",
        "Thailand": "Thailand",
        "Timor Leste": "Timor Leste",
        "Togo": "Togo",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidad and Tobago",
        "Tunisia": "Tunisia",
        "Turkey": "Turkey",
        "Turkmenistan": "Turkmenistan",
        "Turks and Caicos Islands": "Turks and Caicos Islands",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "Ukraine",
        "United Arab Emirates": "United Arab Emirates",
        "United Kingdom": "United Kingdom",
        "United States of America": "United States of America",
        "Uruguay": "Uruguay",
        "Uzbekistan": "Uzbekistan",
        "Vanuatu": "Vanuatu",
        "Vatican City State": "Vatican City State",
        "Venezuela": "Venezuela",
        "Vietnam": "Vietnam",
        "Wallis and Futuna Islands": "Wallis and Futuna Islands",
        "Western Sahara": "Western Sahara",
        "Yemen": "Yemen",
        "Zambia": "Zambia",
        "Zimbabwe": "Zimbabwe"
    },
    "es": {
        //Login
        "Sign In": "Iniciar sesión",
        "Sign in": "Iniciar sesión",
        "Enter your Email": "Introduce tu correo electrónico",
        "Enter your Password": "Introduce tu contraseña",
        "Create Account": "Crear cuenta",
        "Forgot your password?": "¿Olvidaste tu contraseña?",
        "Continue As": "Continuar como",
        "RedirectToWebsite": "Te llevamos de vuelta a",
        "UserMigration failed with error User not found.": "Credenciales no válidas",
        "Signing in": "Iniciando sesión",
        "Incorrect username or password.": "Nombre de usuario o contraseña incorrectos",
        //Reset Psw
        "Reset Password": "Olvidé mi contraseña",
        "Enter your email": "Introduce tu correo electrónico",
        "Send code": "Enviar código",
        "Back to Sign In": "Volver a iniciar sesión",
        //Confirm psw
        "Sending": "Enviando",
        "Code": "Código",
        "New Password": "Nueva Contraseña",
        "Submit": "Confirmar",
        "Your passwords must match": "Las contraseñas deben coincidir",
        "Password must have at least 8 characters": "La contraseña debe tener al menos 8 caracteres",
        "Submitting": "Enviando",
        //Signup
        "Optional": "Opcional",
        "Firstname": "Nombre",
        "Enter_Firstname": "Introduce tu nombre",
        "Surname": "Apellido",
        "Enter_Surname": "Introduce tu apellido",
        "Phone": "Teléfono",
        "Enter_Phone": "Introduce tu número de teléfono",
        "Email": "Correo electrónico",
        "Enter_Email": "Introduce un correo electrónico válido",
        "Password": "Contraseña",
        "Enter_Password": "Introduce una contraseña",
        "Confirm Password": "Confirmar contraseña",
        "Enter_ConfirmPassword": "Confirma tu contraseña",
        "Profile": "Perfil",
        "Select_Profile": "Selecciona un perfil",
        "Country": "País",
        "Select_Country": "Selecciona un país",
        "Company/Studio": "Empresa/Estudio",
        "Enter_Company": "Introduce el nombre de la empresa o estudio",
        "I_read": "He leído",
        "Informativa_privacy": "la política de privacidad",
        "Accetto_privacy": "y acepto recibir comunicaciones de Effe",
        "Error_Profile": "Debes seleccionar un perfil",
        "Error_Company_Studio": "Debes introducir una empresa o estudio",
        "Error_Privacy": "Debes aceptar los términos y condiciones",
        "Error_Country": "Debes seleccionar un país",
        "Error_Password": "Debes introducir una contraseña",
        "Error_ConfirmPassword": "Debes confirmar la contraseña",
        "Error_ConfirmPasswordMatch": "Las dos contraseñas no coinciden",
        "Error_Email": "Debes introducir un correo electrónico válido",
        "Error_Firstname": "Debes introducir un nombre válido",
        "Error_Surname": "Debes introducir un apellido válido",
        //Confirm Account
        "We Emailed You": "Revisa tu correo electrónico",
        "Your code is on the way. To log in, enter the code we emailed to": "Tu código está en camino. Para iniciar sesión, introduce el código que te hemos enviado a",
        "It may take a minute to arrive": "Puede tardar un minuto en llegar",
        "Confirmation Code": "Código de confirmación",
        "Enter your code": "Introduce tu código",
        "Confirm": "Confirmar",
        "Resend Code": "Reenviar código",
        //Perfil
        "Profile_Privato": "Privado",
        "Profile_Architetto_Designer": "Arquitecto/Diseñador",
        "Profile_Ingegnere_Progettista": "Ingeniero/Planificador",
        "Profile_Gestore_Hotel_SPA_Gym": "Gerente de Hotel/Spa/Gimnasio",
        "Profile_Costruttore": "Constructor",
        "Profile_General_Contractor": "Contratista general",
        "Profile_Rivenditore": "Distribuidor",
        "Profile_Agenzia": "Agencia",
        "Profile_Centro_Assistenza_Installatore": "Centro de Servicio/Instalador",
        "Profile_Altro": "Otro",
        //Country
        "Afghanistan": "Afganistán",
        "Albania": "Albania",
        "Algeria": "Argelia",
        "Andorra": "Andorra",
        "Angola": "Angola",
        "Anguilla": "Anguila",
        "Antigua and Barbuda": "Antigua y Barbuda",
        "Argentina": "Argentina",
        "Armenia": "Armenia",
        "Aruba": "Aruba",
        "Australia": "Australia",
        "Austria": "Austria",
        "Azerbaijan": "Azerbaiyán",
        "Bahamas": "Bahamas",
        "Bahrain": "Bahrein",
        "Bangladesh": "Bangladés",
        "Barbados": "Barbados",
        "Belarus": "Bielorrusia",
        "Belgium": "Bélgica",
        "Belize": "Belice",
        "Benin": "Benín",
        "Bermuda": "Bermudas",
        "Bhutan": "Bután",
        "Bolivia": "Bolivia",
        "Bosnia and Herzegovina": "Bosnia y Herzegovina",
        "Botswana": "Botsuana",
        "Brazil": "Brasil",
        "British Virgin Islands": "Islas Vírgenes Británicas",
        "Brunei Darussalam": "Brunéi",
        "Bulgaria": "Bulgaria",
        "Burkina Faso": "Burkina Faso",
        "Burundi": "Burundi",
        "Cambodia": "Camboya",
        "Cameroon": "Camerún",
        "Canada": "Canadá",
        "Cape Verde": "Cabo Verde",
        "Cayman Islands": "Islas Caimán",
        "Central African Republic": "República Centroafricana",
        "Chad": "Chad",
        "Chile": "Chile",
        "China": "China",
        "Colombia": "Colombia",
        "Comoros": "Comoras",
        "Congo": "Congo",
        "Cook Islands (NZ)": "Islas Cook (NZ)",
        "Costa Rica": "Costa Rica",
        "Croatia": "Croacia",
        "Cuba": "Cuba",
        "Curaçao": "Curaçao",
        "Cyprus": "Chipre",
        "Czech Republic": "República Checa",
        "Côte d'Ivoire": "Costa de Marfil",
        "Democratic Republic of the Congo": "República Democrática del Congo",
        "Denmark": "Dinamarca",
        "Djibouti": "Yibuti",
        "Dominica": "Dominica",
        "Dominican Republic": "República Dominicana",
        "Ecuador": "Ecuador",
        "Egypt": "Egipto",
        "El Salvador": "El Salvador",
        "Equatorial Guinea": "Guinea Ecuatorial",
        "Eritrea": "Eritrea",
        "Estonia": "Estonia",
        "Eswatini": "Esuatini",
        "Ethiopia": "Etiopía",
        "Falkland Islands (Malvinas)": "Islas Malvinas",
        "Far East": "Extremo Oriente",
        "Faroe Islands": "Islas Feroe",
        "Federated States of Micronesia": "Estados Federados de Micronesia",
        "Fiji": "Fiyi",
        "Finland": "Finlandia",
        "France": "Francia",
        "French Polynesia": "Polinesia Francesa",
        "French Southern Territories": "Territorios Australes Franceses",
        "Gabon": "Gabón",
        "Gambia": "Gambia",
        "Georgia": "Georgia",
        "Germany": "Alemania",
        "Ghana": "Ghana",
        "Gibraltar": "Gibraltar",
        "Greece": "Grecia",
        "Greenland": "Groenlandia",
        "Grenada": "Granada",
        "Guatemala": "Guatemala",
        "Guernsey": "Guernsey",
        "Guinea": "Guinea",
        "Guinea-Bissau": "Guinea-Bisáu",
        "Guyana": "Guyana",
        "Haiti": "Haití",
        "Honduras": "Honduras",
        "Hungary": "Hungría",
        "Iceland": "Islandia",
        "India": "India",
        "Indonesia": "Indonesia",
        "Iran": "Irán",
        "Iraq": "Iraq",
        "Ireland": "Irlanda",
        "Isle of Man": "Isla de Man",
        "Israel": "Israel",
        "Italia": "Italia",
        "Jamaica": "Jamaica",
        "Japan": "Japón",
        "Jersey": "Jersey",
        "Jordan": "Jordania",
        "Kazakhstan": "Kazajistán",
        "Kenya": "Kenia",
        "Kiribati": "Kiribati",
        "Kosovo": "Kosovo",
        "Kuwait": "Kuwait",
        "Kyrgyzstan": "Kirguistán",
        "Laos": "Laos",
        "Latvia": "Letonia",
        "Lebanon": "Líbano",
        "Lesotho": "Lesoto",
        "Liberia": "Liberia",
        "Libya": "Libia",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Lituania",
        "Luxembourg": "Luxemburgo",
        "North Macedonia": "Macedonia del Norte",
        "Madagascar": "Madagascar",
        "Malawi": "Malaui",
        "Malaysia": "Malasia",
        "Maldives": "Maldivas",
        "Mali": "Mali",
        "Malta": "Malta",
        "Marshall Islands": "Islas Marshall",
        "Mauritania": "Mauritania",
        "Mauritius": "Mauricio",
        "Mexico": "México",
        "Moldova": "Moldavia",
        "Monaco": "Mónaco",
        "Mongolia": "Mongolia",
        "Montenegro": "Montenegro",
        "Montserrat": "Montserrat",
        "Morocco": "Marruecos",
        "Mozambique": "Mozambique",
        "Myanmar/Burma": "Myanmar/Birmania",
        "Namibia": "Namibia",
        "Nauru": "Nauru",
        "Nepal": "Nepal",
        "Netherlands": "Países Bajos",
        "New Caledonia": "Nueva Caledonia",
        "New Zealand": "Nueva Zelanda",
        "Nicaragua": "Nicaragua",
        "Niger": "Níger",
        "Nigeria": "Nigeria",
        "North Korea": "Corea del Norte",
        "Norway": "Noruega",
        "Oman": "Omán",
        "Pakistan": "Pakistán",
        "Palau": "Palaos",
        "Palestine": "Palestina",
        "Panama": "Panamá",
        "Papua New Guinea": "Papúa Nueva Guinea",
        "Paraguay": "Paraguay",
        "Peru": "Perú",
        "Philippines": "Filipinas",
        "Pitcairn": "Pitcairn",
        "Poland": "Polonia",
        "Portugal": "Portugal",
        "Puerto Rico": "Puerto Rico",
        "Qatar": "Catar",
        "Romania": "Rumanía",
        "Russian Federation": "Federación Rusa",
        "Rwanda": "Ruanda",
        "Saint Barthelemy": "San Bartolomé",
        "Saint Helena": "Santa Elena",
        "Saint Kitts and Nevis": "San Cristóbal y Nieves",
        "Saint Lucia": "Santa Lucía",
        "Saint Martin (FR)": "San Martín (FR)",
        "Saint Pierre and Miquelon": "San Pedro y Miquelón",
        "Saint Vincent and the Grenadines": "San Vicente y las Granadinas",
        "Samoa": "Samoa",
        "San Marino": "San Marino",
        "San Marino (RSM)": "San Marino (RSM)",
        "Sao Tome and Principe": "Santo Tomé y Príncipe",
        "Sark": "Sark",
        "Saudi Arabia": "Arabia Saudita",
        "Senegal": "Senegal",
        "Serbia": "Serbia",
        "Seychelles": "Seychelles",
        "Sierra Leone": "Sierra Leona",
        "Singapore": "Singapur",
        "Slovakia": "Eslovaquia",
        "Slovenia": "Eslovenia",
        "Solomon Islands": "Islas Salomón",
        "Somalia": "Somalia",
        "South Africa": "Sudáfrica",
        "South Korea": "Corea del Sur",
        "South Sudan": "Sudán del Sur",
        "Spain": "España",
        "Sri Lanka": "Sri Lanka",
        "St Maarten (NL)": "San Martín (NL)",
        "Sudan": "Sudán",
        "Suriname": "Surinam",
        "Sweden": "Suecia",
        "Switzerland": "Suiza",
        "Syria": "Siria",
        "Taiwan": "Taiwán",
        "Tajikistan": "Tayikistán",
        "Tanzania": "Tanzania",
        "Thailand": "Tailandia",
        "Timor Leste": "Timor Oriental",
        "Togo": "Togo",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidad y Tobago",
        "Tunisia": "Túnez",
        "Turkey": "Turquía",
        "Turkmenistan": "Turkmenistán",
        "Turks and Caicos Islands": "Islas Turcas y Caicos",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "Ucrania",
        "United Arab Emirates": "Emiratos Árabes Unidos",
        "United Kingdom": "Reino Unido",
        "United States of America": "Estados Unidos de América",
        "Uruguay": "Uruguay",
        "Uzbekistan": "Uzbekistán",
        "Vanuatu": "Vanuatu",
        "Vatican City State": "Ciudad del Vaticano",
        "Venezuela": "Venezuela",
        "Vietnam": "Vietnam",
        "Wallis and Futuna Islands": "Islas Wallis y Futuna",
        "Western Sahara": "Sahara Occidental",
        "Yemen": "Yemen",
        "Zambia": "Zambia",
        "Zimbabwe": "Zimbabue"
    },
    "de": {
        //Login
        "Sign In": "Anmelden",
        "Sign in": "Anmelden",
        "Enter your Email": "E-Mail eingeben",
        "Enter your Password": "Passwort eingeben",
        "Create Account": "Konto erstellen",
        "Forgot your password?": "Passwort vergessen?",
        "Continue As": "Weiter als",
        "RedirectToWebsite": "Wir bringen Sie zurück zu",
        "UserMigration failed with error User not found.": "Ungültige Anmeldedaten",
        "Signing in": "Anmeldung läuft",
        "Incorrect username or password.": "Benutzername oder Passwort falsch",
        //Reset Psw
        "Reset Password": "Passwort vergessen",
        "Enter your email": "E-Mail eingeben",
        "Send code": "Code senden",
        "Back to Sign In": "Zurück zur Anmeldung",
        //Confirm psw
        "Sending": "Senden",
        "Code": "Code",
        "New Password": "Neues Passwort",
        "Submit": "Bestätigen",
        "Your passwords must match": "Die Passwörter müssen übereinstimmen",
        "Password must have at least 8 characters": "Das Passwort muss mindestens 8 Zeichen enthalten",
        "Submitting": "Senden",
        //Signup
        "Optional": "Optional",
        "Firstname": "Vorname",
        "Enter_Firstname": "Vorname eingeben",
        "Surname": "Nachname",
        "Enter_Surname": "Nachname eingeben",
        "Phone": "Telefonnummer",
        "Enter_Phone": "Telefonnummer eingeben",
        "Email": "E-Mail",
        "Enter_Email": "Gültige E-Mail eingeben",
        "Password": "Passwort",
        "Enter_Password": "Passwort eingeben",
        "Confirm Password": "Passwort bestätigen",
        "Enter_ConfirmPassword": "Passwort bestätigen",
        "Profile": "Profil",
        "Select_Profile": "Profil auswählen",
        "Country": "Land",
        "Select_Country": "Land auswählen",
        "Company/Studio": "Firma/Studio",
        "Enter_Company": "Firmen- oder Studioname eingeben",
        "I_read": "Ich habe gelesen",
        "Informativa_privacy": "die Datenschutzerklärung",
        "Accetto_privacy": "und stimme zu, Mitteilungen von Effe zu erhalten",
        "Error_Profile": "Sie müssen ein Profil auswählen",
        "Error_Company_Studio": "Sie müssen eine Firma oder ein Studio eingeben",
        "Error_Privacy": "Sie müssen den Bedingungen zustimmen",
        "Error_Country": "Sie müssen ein Land auswählen",
        "Error_Password": "Sie müssen ein Passwort eingeben",
        "Error_ConfirmPassword": "Sie müssen das Passwort bestätigen",
        "Error_ConfirmPasswordMatch": "Die beiden Passwörter stimmen nicht überein",
        "Error_Email": "Sie müssen eine gültige E-Mail eingeben",
        "Error_Firstname": "Sie müssen einen gültigen Vornamen eingeben",
        "Error_Surname": "Sie müssen einen gültigen Nachnamen eingeben",
        //Confirm Account
        "We Emailed You": "Überprüfen Sie Ihre E-Mails",
        "Your code is on the way. To log in, enter the code we emailed to": "Ihr Code ist unterwegs. Zum Einloggen geben Sie den Code ein, den wir Ihnen per E-Mail gesendet haben an",
        "It may take a minute to arrive": "Es kann eine Minute dauern, bis er ankommt",
        "Confirmation Code": "Bestätigungscode",
        "Enter your code": "Geben Sie Ihren Code ein",
        "Confirm": "Bestätigen",
        "Resend Code": "Code erneut senden",
        //Profil
        "Profile_Privato": "Privat",
        "Profile_Architetto_Designer": "Architekt/Designer",
        "Profile_Ingegnere_Progettista": "Ingenieur/Planer",
        "Profile_Gestore_Hotel_SPA_Gym": "Hotel/SPA/Fitnessstudio Manager",
        "Profile_Costruttore": "Bauunternehmer",
        "Profile_General_Contractor": "Generalunternehmer",
        "Profile_Rivenditore": "Händler",
        "Profile_Agenzia": "Agentur",
        "Profile_Centro_Assistenza_Installatore": "Servicecenter/Installateur",
        "Profile_Altro": "Andere",
        //Country
        "Afghanistan": "Afghanistan",
        "Albania": "Albanien",
        "Algeria": "Algerien",
        "Andorra": "Andorra",
        "Angola": "Angola",
        "Anguilla": "Anguilla",
        "Antigua and Barbuda": "Antigua und Barbuda",
        "Argentina": "Argentinien",
        "Armenia": "Armenien",
        "Aruba": "Aruba",
        "Australia": "Australien",
        "Austria": "Österreich",
        "Azerbaijan": "Aserbaidschan",
        "Bahamas": "Bahamas",
        "Bahrain": "Bahrain",
        "Bangladesh": "Bangladesch",
        "Barbados": "Barbados",
        "Belarus": "Weißrussland",
        "Belgium": "Belgien",
        "Belize": "Belize",
        "Benin": "Benin",
        "Bermuda": "Bermuda",
        "Bhutan": "Bhutan",
        "Bolivia": "Bolivien",
        "Bosnia and Herzegovina": "Bosnien und Herzegowina",
        "Botswana": "Botsuana",
        "Brazil": "Brasilien",
        "British Virgin Islands": "Britische Jungferninseln",
        "Brunei Darussalam": "Brunei Darussalam",
        "Bulgaria": "Bulgarien",
        "Burkina Faso": "Burkina Faso",
        "Burundi": "Burundi",
        "Cambodia": "Kambodscha",
        "Cameroon": "Kamerun",
        "Canada": "Kanada",
        "Cape Verde": "Kap Verde",
        "Cayman Islands": "Kaimaninseln",
        "Central African Republic": "Zentralafrikanische Republik",
        "Chad": "Tschad",
        "Chile": "Chile",
        "China": "China",
        "Colombia": "Kolumbien",
        "Comoros": "Komoren",
        "Congo": "Kongo",
        "Cook Islands (NZ)": "Cookinseln (NZ)",
        "Costa Rica": "Costa Rica",
        "Croatia": "Kroatien",
        "Cuba": "Kuba",
        "Curaçao": "Curaçao",
        "Cyprus": "Zypern",
        "Czech Republic": "Tschechische Republik",
        "Côte d'Ivoire": "Elfenbeinküste",
        "Democratic Republic of the Congo": "Demokratische Republik Kongo",
        "Denmark": "Dänemark",
        "Djibouti": "Dschibuti",
        "Dominica": "Dominica",
        "Dominican Republic": "Dominikanische Republik",
        "Ecuador": "Ecuador",
        "Egypt": "Ägypten",
        "El Salvador": "El Salvador",
        "Equatorial Guinea": "Äquatorialguinea",
        "Eritrea": "Eritrea",
        "Estonia": "Estland",
        "Eswatini": "Eswatini",
        "Ethiopia": "Äthiopien",
        "Falkland Islands (Malvinas)": "Falklandinseln (Malvinas)",
        "Far East": "Ferner Osten",
        "Faroe Islands": "Färöer-Inseln",
        "Federated States of Micronesia": "Föderierte Staaten von Mikronesien",
        "Fiji": "Fidschi",
        "Finland": "Finnland",
        "France": "Frankreich",
        "French Polynesia": "Französisch-Polynesien",
        "French Southern Territories": "Französische Süd- und Antarktisgebiete",
        "Gabon": "Gabun",
        "Gambia": "Gambia",
        "Georgia": "Georgien",
        "Germany": "Deutschland",
        "Ghana": "Ghana",
        "Gibraltar": "Gibraltar",
        "Greece": "Griechenland",
        "Greenland": "Grönland",
        "Grenada": "Grenada",
        "Guatemala": "Guatemala",
        "Guernsey": "Guernsey",
        "Guinea": "Guinea",
        "Guinea-Bissau": "Guinea-Bissau",
        "Guyana": "Guyana",
        "Haiti": "Haiti",
        "Honduras": "Honduras",
        "Hungary": "Ungarn",
        "Iceland": "Island",
        "India": "Indien",
        "Indonesia": "Indonesien",
        "Iran": "Iran",
        "Iraq": "Irak",
        "Ireland": "Irland",
        "Isle of Man": "Isle of Man",
        "Israel": "Israel",
        "Italia": "Italien",
        "Jamaica": "Jamaika",
        "Japan": "Japan",
        "Jersey": "Jersey",
        "Jordan": "Jordanien",
        "Kazakhstan": "Kasachstan",
        "Kenya": "Kenia",
        "Kiribati": "Kiribati",
        "Kosovo": "Kosovo",
        "Kuwait": "Kuwait",
        "Kyrgyzstan": "Kirgisistan",
        "Laos": "Laos",
        "Latvia": "Lettland",
        "Lebanon": "Libanon",
        "Lesotho": "Lesotho",
        "Liberia": "Liberia",
        "Libya": "Libyen",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Litauen",
        "Luxembourg": "Luxemburg",
        "North Macedonia": "Nordmazedonien",
        "Madagascar": "Madagaskar",
        "Malawi": "Malawi",
        "Malaysia": "Malaysia",
        "Maldives": "Malediven",
        "Mali": "Mali",
        "Malta": "Malta",
        "Marshall Islands": "Marshallinseln",
        "Mauritania": "Mauretanien",
        "Mauritius": "Mauritius",
        "Mexico": "Mexiko",
        "Moldova": "Moldawien",
        "Monaco": "Monaco",
        "Mongolia": "Mongolei",
        "Montenegro": "Montenegro",
        "Montserrat": "Montserrat",
        "Morocco": "Marokko",
        "Mozambique": "Mosambik",
        "Myanmar/Burma": "Myanmar/Birma",
        "Namibia": "Namibia",
        "Nauru": "Nauru",
        "Nepal": "Nepal",
        "Netherlands": "Niederlande",
        "New Caledonia": "Neukaledonien",
        "New Zealand": "Neuseeland",
        "Nicaragua": "Nicaragua",
        "Niger": "Niger",
        "Nigeria": "Nigeria",
        "North Korea": "Nordkorea",
        "Norway": "Norwegen",
        "Oman": "Oman",
        "Pakistan": "Pakistan",
        "Palau": "Palau",
        "Palestine": "Palästina",
        "Panama": "Panama",
        "Papua New Guinea": "Papua-Neuguinea",
        "Paraguay": "Paraguay",
        "Peru": "Peru",
        "Philippines": "Philippinen",
        "Pitcairn": "Pitcairn",
        "Poland": "Polen",
        "Portugal": "Portugal",
        "Puerto Rico": "Puerto Rico",
        "Qatar": "Katar",
        "Romania": "Rumänien",
        "Russian Federation": "Russische Föderation",
        "Rwanda": "Ruanda",
        "Saint Barthelemy": "Saint Barthelemy",
        "Saint Helena": "St. Helena",
        "Saint Kitts and Nevis": "St. Kitts und Nevis",
        "Saint Lucia": "St. Lucia",
        "Saint Martin (FR)": "Saint Martin (FR)",
        "Saint Pierre and Miquelon": "St. Pierre und Miquelon",
        "Saint Vincent and the Grenadines": "St. Vincent und die Grenadinen",
        "Samoa": "Samoa",
        "San Marino": "San Marino",
        "San Marino (RSM)": "San Marino (RSM)",
        "Sao Tome and Principe": "São Tomé und Príncipe",
        "Sark": "Sark",
        "Saudi Arabia": "Saudi-Arabien",
        "Senegal": "Senegal",
        "Serbia": "Serbien",
        "Seychelles": "Seychellen",
        "Sierra Leone": "Sierra Leone",
        "Singapore": "Singapur",
        "Slovakia": "Slowakei",
        "Slovenia": "Slowenien",
        "Solomon Islands": "Salomonen",
        "Somalia": "Somalia",
        "South Africa": "Südafrika",
        "South Korea": "Südkorea",
        "South Sudan": "Südsudan",
        "Spain": "Spanien",
        "Sri Lanka": "Sri Lanka",
        "St Maarten (NL)": "St. Maarten (NL)",
        "Sudan": "Sudan",
        "Suriname": "Suriname",
        "Sweden": "Schweden",
        "Switzerland": "Schweiz",
        "Syria": "Syrien",
        "Taiwan": "Taiwan",
        "Tajikistan": "Tadschikistan",
        "Tanzania": "Tansania",
        "Thailand": "Thailand",
        "Timor Leste": "Timor-Leste",
        "Togo": "Togo",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinidad und Tobago",
        "Tunisia": "Tunesien",
        "Turkey": "Türkei",
        "Turkmenistan": "Turkmenistan",
        "Turks and Caicos Islands": "Turks- und Caicosinseln",
        "Tuvalu": "Tuvalu",
        "Uganda": "Uganda",
        "Ukraine": "Ukraine",
        "United Arab Emirates": "Vereinigte Arabische Emirate",
        "United Kingdom": "Vereinigtes Königreich",
        "United States of America": "Vereinigte Staaten von Amerika",
        "Uruguay": "Uruguay",
        "Uzbekistan": "Usbekistan",
        "Vanuatu": "Vanuatu",
        "Vatican City State": "Vatikanstadt",
        "Venezuela": "Venezuela",
        "Vietnam": "Vietnam",
        "Wallis and Futuna Islands": "Wallis und Futuna",
        "Western Sahara": "Westsahara",
        "Yemen": "Jemen",
        "Zambia": "Sambia",
        "Zimbabwe": "Simbabwe"
    },
    "fr": {
        //Login
        "Sign In": "Se connecter",
        "Sign in": "Se connecter",
        "Enter your Email": "Entrez votre e-mail",
        "Enter your Password": "Entrez votre mot de passe",
        "Create Account": "Créer un compte",
        "Forgot your password?": "Mot de passe oublié ?",
        "Continue As": "Continuer en tant que",
        "RedirectToWebsite": "Nous vous ramenons à",
        "UserMigration failed with error User not found.": "Identifiants invalides",
        "Signing in": "Connexion en cours",
        "Incorrect username or password.": "Nom d'utilisateur ou mot de passe incorrect",
        //Reset Psw
        "Reset Password": "Mot de passe oublié",
        "Enter your email": "Entrez votre e-mail",
        "Send code": "Envoyer le code",
        "Back to Sign In": "Retour à la connexion",
        //Confirm psw
        "Sending": "Envoi en cours",
        "Code": "Code",
        "New Password": "Nouveau Mot de Passe",
        "Submit": "Confirmer",
        "Your passwords must match": "Les mots de passe doivent correspondre",
        "Password must have at least 8 characters": "Le mot de passe doit contenir au moins 8 caractères",
        "Submitting": "Envoi en cours",
        //Signup
        "Optional": "Optionnel",
        "Firstname": "Prénom",
        "Enter_Firstname": "Entrez votre prénom",
        "Surname": "Nom",
        "Enter_Surname": "Entrez votre nom",
        "Phone": "Téléphone",
        "Enter_Phone": "Entrez votre numéro de téléphone",
        "Email": "E-mail",
        "Enter_Email": "Entrez une adresse e-mail valide",
        "Password": "Mot de passe",
        "Enter_Password": "Entrez un mot de passe",
        "Confirm Password": "Confirmez le mot de passe",
        "Enter_ConfirmPassword": "Confirmez votre mot de passe",
        "Profile": "Profil",
        "Select_Profile": "Sélectionnez un profil",
        "Country": "Pays",
        "Select_Country": "Sélectionnez un pays",
        "Company/Studio": "Entreprise/Studio",
        "Enter_Company": "Entrez le nom de l'entreprise ou du studio",
        "I_read": "J'ai lu",
        "Informativa_privacy": "la politique de confidentialité",
        "Accetto_privacy": "et j'accepte de recevoir des communications de la part de Effe",
        "Error_Profile": "Vous devez sélectionner un profil",
        "Error_Company_Studio": "Vous devez entrer une entreprise ou un studio",
        "Error_Privacy": "Vous devez accepter les termes et conditions",
        "Error_Country": "Vous devez sélectionner un pays",
        "Error_Password": "Vous devez entrer un mot de passe",
        "Error_ConfirmPassword": "Vous devez confirmer le mot de passe",
        "Error_ConfirmPasswordMatch": "Les deux mots de passe ne correspondent pas",
        "Error_Email": "Vous devez entrer une adresse e-mail valide",
        "Error_Firstname": "Vous devez entrer un prénom valide",
        "Error_Surname": "Vous devez entrer un nom valide",
        //Confirm Account
        "We Emailed You": "Vérifiez votre e-mail",
        "Your code is on the way. To log in, enter the code we emailed to": "Votre code est en route. Pour vous connecter, entrez le code que nous vous avons envoyé par e-mail à",
        "It may take a minute to arrive": "Cela peut prendre une minute pour arriver",
        "Confirmation Code": "Code de confirmation",
        "Enter your code": "Entrez votre code",
        "Confirm": "Confirmer",
        "Resend Code": "Renvoyer le code",
        //Profil
        "Profile_Privato": "Privé",
        "Profile_Architetto_Designer": "Architecte/Designer",
        "Profile_Ingegnere_Progettista": "Ingénieur/Planificateur",
        "Profile_Gestore_Hotel_SPA_Gym": "Gérant d'Hôtel/Spa/Gym",
        "Profile_Costruttore": "Constructeur",
        "Profile_General_Contractor": "Entrepreneur général",
        "Profile_Rivenditore": "Revendeur",
        "Profile_Agenzia": "Agence",
        "Profile_Centro_Assistenza_Installatore": "Centre de service/Installateur",
        "Profile_Altro": "Autre",
        //Country
        "Afghanistan": "Afghanistan",
        "Albania": "Albanie",
        "Algeria": "Algérie",
        "Andorra": "Andorre",
        "Angola": "Angola",
        "Anguilla": "Anguilla",
        "Antigua and Barbuda": "Antigua-et-Barbuda",
        "Argentina": "Argentine",
        "Armenia": "Arménie",
        "Aruba": "Aruba",
        "Australia": "Australie",
        "Austria": "Autriche",
        "Azerbaijan": "Azerbaïdjan",
        "Bahamas": "Bahamas",
        "Bahrain": "Bahreïn",
        "Bangladesh": "Bangladesh",
        "Barbados": "Barbade",
        "Belarus": "Bélarus",
        "Belgium": "Belgique",
        "Belize": "Belize",
        "Benin": "Bénin",
        "Bermuda": "Bermudes",
        "Bhutan": "Bhoutan",
        "Bolivia": "Bolivie",
        "Bosnia and Herzegovina": "Bosnie-Herzégovine",
        "Botswana": "Botswana",
        "Brazil": "Brésil",
        "British Virgin Islands": "Îles Vierges britanniques",
        "Brunei Darussalam": "Brunei Darussalam",
        "Bulgaria": "Bulgarie",
        "Burkina Faso": "Burkina Faso",
        "Burundi": "Burundi",
        "Cambodia": "Cambodge",
        "Cameroon": "Cameroun",
        "Canada": "Canada",
        "Cape Verde": "Cap-Vert",
        "Cayman Islands": "Îles Caïmans",
        "Central African Republic": "République centrafricaine",
        "Chad": "Tchad",
        "Chile": "Chili",
        "China": "Chine",
        "Colombia": "Colombie",
        "Comoros": "Comores",
        "Congo": "Congo",
        "Cook Islands (NZ)": "Îles Cook (NZ)",
        "Costa Rica": "Costa Rica",
        "Croatia": "Croatie",
        "Cuba": "Cuba",
        "Curaçao": "Curaçao",
        "Cyprus": "Chypre",
        "Czech Republic": "République tchèque",
        "Côte d'Ivoire": "Côte d'Ivoire",
        "Democratic Republic of the Congo": "République démocratique du Congo",
        "Denmark": "Danemark",
        "Djibouti": "Djibouti",
        "Dominica": "Dominique",
        "Dominican Republic": "République dominicaine",
        "Ecuador": "Équateur",
        "Egypt": "Égypte",
        "El Salvador": "El Salvador",
        "Equatorial Guinea": "Guinée équatoriale",
        "Eritrea": "Érythrée",
        "Estonia": "Estonie",
        "Eswatini": "Eswatini",
        "Ethiopia": "Éthiopie",
        "Falkland Islands (Malvinas)": "Îles Falkland (Malouines)",
        "Far East": "Extrême-Orient",
        "Faroe Islands": "Îles Féroé",
        "Federated States of Micronesia": "États fédérés de Micronésie",
        "Fiji": "Fidji",
        "Finland": "Finlande",
        "France": "France",
        "French Polynesia": "Polynésie française",
        "French Southern Territories": "Terres australes et antarctiques françaises",
        "Gabon": "Gabon",
        "Gambia": "Gambie",
        "Georgia": "Géorgie",
        "Germany": "Allemagne",
        "Ghana": "Ghana",
        "Gibraltar": "Gibraltar",
        "Greece": "Grèce",
        "Greenland": "Groenland",
        "Grenada": "Grenade",
        "Guatemala": "Guatemala",
        "Guernsey": "Guernesey",
        "Guinea": "Guinée",
        "Guinea-Bissau": "Guinée-Bissau",
        "Guyana": "Guyana",
        "Haiti": "Haïti",
        "Honduras": "Honduras",
        "Hungary": "Hongrie",
        "Iceland": "Islande",
        "India": "Inde",
        "Indonesia": "Indonésie",
        "Iran": "Iran",
        "Iraq": "Iraq",
        "Ireland": "Irlande",
        "Isle of Man": "Île de Man",
        "Israel": "Israël",
        "Italia": "Italie",
        "Jamaica": "Jamaïque",
        "Japan": "Japon",
        "Jersey": "Jersey",
        "Jordan": "Jordanie",
        "Kazakhstan": "Kazakhstan",
        "Kenya": "Kenya",
        "Kiribati": "Kiribati",
        "Kosovo": "Kosovo",
        "Kuwait": "Koweït",
        "Kyrgyzstan": "Kirghizistan",
        "Laos": "Laos",
        "Latvia": "Lettonie",
        "Lebanon": "Liban",
        "Lesotho": "Lesotho",
        "Liberia": "Libéria",
        "Libya": "Libye",
        "Liechtenstein": "Liechtenstein",
        "Lithuania": "Lituanie",
        "Luxembourg": "Luxembourg",
        "North Macedonia": "Macédoine du Nord",
        "Madagascar": "Madagascar",
        "Malawi": "Malawi",
        "Malaysia": "Malaisie",
        "Maldives": "Maldives",
        "Mali": "Mali",
        "Malta": "Malte",
        "Marshall Islands": "Îles Marshall",
        "Mauritania": "Mauritanie",
        "Mauritius": "Maurice",
        "Mexico": "Mexique",
        "Moldova": "Moldavie",
        "Monaco": "Monaco",
        "Mongolia": "Mongolie",
        "Montenegro": "Monténégro",
        "Montserrat": "Montserrat",
        "Morocco": "Maroc",
        "Mozambique": "Mozambique",
        "Myanmar/Burma": "Myanmar/Birmanie",
        "Namibia": "Namibie",
        "Nauru": "Nauru",
        "Nepal": "Népal",
        "Netherlands": "Pays-Bas",
        "New Caledonia": "Nouvelle-Calédonie",
        "New Zealand": "Nouvelle-Zélande",
        "Nicaragua": "Nicaragua",
        "Niger": "Niger",
        "Nigeria": "Nigeria",
        "North Korea": "Corée du Nord",
        "Norway": "Norvège",
        "Oman": "Oman",
        "Pakistan": "Pakistan",
        "Palau": "Palaos",
        "Palestine": "Palestine",
        "Panama": "Panama",
        "Papua New Guinea": "Papouasie-Nouvelle-Guinée",
        "Paraguay": "Paraguay",
        "Peru": "Pérou",
        "Philippines": "Philippines",
        "Pitcairn": "Pitcairn",
        "Poland": "Pologne",
        "Portugal": "Portugal",
        "Puerto Rico": "Porto Rico",
        "Qatar": "Qatar",
        "Romania": "Roumanie",
        "Russian Federation": "Fédération de Russie",
        "Rwanda": "Rwanda",
        "Saint Barthelemy": "Saint-Barthélemy",
        "Saint Helena": "Sainte-Hélène",
        "Saint Kitts and Nevis": "Saint-Kitts-et-Nevis",
        "Saint Lucia": "Sainte-Lucie",
        "Saint Martin (FR)": "Saint-Martin (FR)",
        "Saint Pierre and Miquelon": "Saint-Pierre-et-Miquelon",
        "Saint Vincent and the Grenadines": "Saint-Vincent-et-les-Grenadines",
        "Samoa": "Samoa",
        "San Marino": "Saint-Marin",
        "San Marino (RSM)": "Saint-Marin (RSM)",
        "Sao Tome and Principe": "Sao Tomé-et-Principe",
        "Sark": "Sercq",
        "Saudi Arabia": "Arabie saoudite",
        "Senegal": "Sénégal",
        "Serbia": "Serbie",
        "Seychelles": "Seychelles",
        "Sierra Leone": "Sierra Leone",
        "Singapore": "Singapour",
        "Slovakia": "Slovaquie",
        "Slovenia": "Slovénie",
        "Solomon Islands": "Îles Salomon",
        "Somalia": "Somalie",
        "South Africa": "Afrique du Sud",
        "South Korea": "Corée du Sud",
        "South Sudan": "Soudan du Sud",
        "Spain": "Espagne",
        "Sri Lanka": "Sri Lanka",
        "St Maarten (NL)": "Saint-Martin (NL)",
        "Sudan": "Soudan",
        "Suriname": "Suriname",
        "Sweden": "Suède",
        "Switzerland": "Suisse",
        "Syria": "Syrie",
        "Taiwan": "Taïwan",
        "Tajikistan": "Tadjikistan",
        "Tanzania": "Tanzanie",
        "Thailand": "Thaïlande",
        "Timor Leste": "Timor-Leste",
        "Togo": "Togo",
        "Tonga": "Tonga",
        "Trinidad and Tobago": "Trinité-et-Tobago",
        "Tunisia": "Tunisie",
        "Turkey": "Turquie",
        "Turkmenistan": "Turkménistan",
        "Turks and Caicos Islands": "Îles Turques-et-Caïques",
        "Tuvalu": "Tuvalu",
        "Uganda": "Ouganda",
        "Ukraine": "Ukraine",
        "United Arab Emirates": "Émirats arabes unis",
        "United Kingdom": "Royaume-Uni",
        "United States of America": "États-Unis d'Amérique",
        "Uruguay": "Uruguay",
        "Uzbekistan": "Ouzbékistan",
        "Vanuatu": "Vanuatu",
        "Vatican City State": "État de la Cité du Vatican",
        "Venezuela": "Venezuela",
        "Vietnam": "Vietnam",
        "Wallis and Futuna Islands": "Îles Wallis-et-Futuna",
        "Western Sahara": "Sahara occidental",
        "Yemen": "Yémen",
        "Zambia": "Zambie",
        "Zimbabwe": "Zimbabwe"
    }
};